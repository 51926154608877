<template>
  <div class="new-clients-container">
    <admin-header-vue
      alias=""
      pageName="New Topic" />
    <form @submit.prevent="handleSubmit">
      <div class="new-client form-group">
        <div class="columns">
          <div class="column is-3" v-if="moduleOptions && moduleOptions.length">
            <p>Module<sup class="display-flex required">*</sup></p>
            <search-drop-down
              label=""
              :showLabel="true"
              :list="moduleOptions"
              :selected="selectedModule"
              @onChange="onChangeModule" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :required="true"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onEnter="handleSubmit"
              @onChangeName="onChangeName"
              label="Topic Name" />
          </div>
          <div class="column is-4">
            <input-vue
              :required="false"
              type="text"
              :max="200"
              @onEnter="handleSubmit"
              :submitted="submitted"
              @onChangeName="onChangehelp"
              label="Help Text (Description)" />
          </div>
          <div class="column is-4">
            <p>Is Standard?</p>
            <b-checkbox v-model="newTopic.isStandard"> </b-checkbox>
          </div>
        </div>
        <div
          v-if="!newTopic.isStandard && selectedModule.name === 'voc'"
          class="columns ">
          <div class="column is-4">
            <select-vue
              :value="newTopic.categoryId"
              label="Category Name"
              placeholder="Select a category"
              :list="categoryList"
              :submitted="submitted"
              :required="true"
              @onChangeSelection="onChangeCategoryName"
              id="category_id"
              name="category_name" />
          </div>
          <div class="column is-4">
            <select-vue
              :value="newTopic.parentId"
              label="Parent Topic Name"
              placeholder="Select a parent topic name"
              :list="parentTopicList"
              :submitted="submitted"
              :required="false"
              @onChangeSelection="onChangeParentTopicName"
              id="topic_id"
              name="topic_name" />
          </div>
        </div>
        <!-- for voe -->
        <div
          v-if="!newTopic.isStandard && selectedModule.name === 'voe'"
          class="columns form-group">
          <div class="column is-4">
            <!-- industry treeselect -->
            <p>Industry<sup class="display-flex required">*</sup></p>
            <treeselect
              :multiple="false"
              :options="allIndustryList"
              :sort-value-by="sortValueBy"
              :default-expand-level="1"
              placeholder="Select Industry"
              v-model="newTopic.industryId" />
              <div v-if="submitted && isIndustryIdInValid" class="required-error">
                Industry is required
              </div>
            </div>
          <div class="column is-4">
            <select-vue
              :value="newTopic.parentId"
              label="Parent Topic Name"
              placeholder="Select a parent topic name"
              :list="parentTopicList"
              :submitted="submitted"
              :required="false"
              @onChangeSelection="onChangeParentTopicName"
              id="topic_id"
              name="topic_name" />
          </div>
        </div>
        <!-- <div class="columns">
          <p class="column is-12 ">Keywords</p>
        </div> -->
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :required="false"
              type="textarea"
              :submitted="submitted"
              @input="handleInclusionInput"
              label="Inclusions (Each line represents a keyword)" />
            <div
              class="required-error"
              v-if="submitted && isInclusionsInvalid">
              {{inclusionsError}}
              <!-- Max 5000 keywords accepted. -->
            </div>
          </div>
          <div class="column is-4">
            <input-vue
              :required="false"
              type="textarea"
              :submitted="submitted"
              @input="handleExclusionInput"
              label="Exclusions (Each line represents a keyword)" />
            <div
              class="required-error"
              v-if="submitted && isExclusionsInvalid">
              {{exclusionsError}}
              <!-- Max 5000 keywords accepted. -->
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Add</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import InputVue from '../../components/Input.vue'
import SelectVue from '../../components/Select.vue'
import Snackbar from '../../../../components/Snackbar'
import SearchDropDown from '../../components/SearchDropDown.vue'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
/**
 * Form to create a Topic.
 * @displayName AddTopic
 */
export default {
  name: 'AddTopic',
  components: {
    AdminHeaderVue,
    InputVue,
    SelectVue,
    SearchDropDown,
    Treeselect
  },
  data () {
    return {
      /**
       * Object containing form data which will be sent to the API
       * @type {Object}
       */
      newTopic: {
        topicName: null,
        categoryId: null,
        industryId: null,
        isStandard: false,
        parentId: null,
        helpText: null,
        keywords: {
          inclusions: [],
          exclusions: []
        }
      },
      /**
       * Field to track the form submission status, to be used to show/hide the validation messages
       * @type {Boolean}
       */
      submitted: false,

      /**
       * Field to track if the topic name input is valid
       * @type {Boolean}
       */
      isTopicNameInvalid: true,

      /**
       * Field to track if the category input is valid
       * @type {Boolean}
       */
      isCategoryIdInvalid: true,

      /**
       * Field to track if the parent topic input is valid
       * @type {Boolean}
       */
      isParentIdInvalid: false,

      /**
       * Field to track if the tagging keyword inclusions input is valid
       * @type {Boolean}bo
       */
      isInclusionsInvalid: false,

      /**
       * Field to track if the tagging keyword exclusions input is valid
       * @data
       * @type {Boolean}
       */
      isExclusionsInvalid: false,
      inclusionsError: '',
      exclusionsError: '',
      selectedModule: {
        id: null,
        name: null
      },
      // industryOptions: [],
      sortValueBy: 'ORDER_SELECTED'
    }
  },
  computed: {
    ...mapState('categories', ['categoryList']),
    ...mapState('topics', ['parentTopicList']),
    ...mapState('modules', ['moduleList']),
    ...mapState('industries', ['allIndustryList', 'allIndustryIds']),
    moduleOptions () {
      if (this.moduleList && this.moduleList.length) {
        return this.moduleList.filter(m => m.module_name === 'voc' || m.module_name === 'voe').map(({ module_id: id, module_name: name }) => ({ id, name }))
      } else {
        return []
      }
    },
    isIndustryIdInValid () {
      if (this.newTopic.isStandard && this.newTopic.industryId) {
        return false
      } else {
        if (!this.newTopic.industryId) {
          return true
        } else {
          return false
        }
      }
    }
  },
  watch: {
    'newTopic.industryId' () {
      if (this.newTopic.industryId != null) {
        this.getFilteredVoeTopicList(this.newTopic.industryId)
      }
    },
    moduleOptions () {
      this.selectedModule = this.moduleOptions[0]
    },
    selectedModule () {
      this.newTopic.module = this.selectedModule.name
      this.resetForm()
    },
    'newTopic.isStandard' () {
      if (this.newTopic.isStandard) {
        this.newTopic.categoryId = null
        this.newTopic.parentId = null
        this.resetFilteredParentTopics()
        this.newTopic.industryId = null
      }
    }
  },
  beforeMount () {
    this.resetCategoryList()
    this.resetFilteredParentTopics()
  },
  mounted () {
    this.getModuleList()
    this.getCategoryList()
    this.getHeirarchicalIndustryList()
  },
  methods: {
    ...mapActions('topics', ['getFilteredTopicList', 'resetFilteredParentTopics', 'addTopic', 'getFilteredVoeTopicList']),
    ...mapActions('categories', ['getCategoryList', 'resetCategoryList']),
    ...mapActions('modules', ['getModuleList']),
    ...mapActions('industries', ['getHeirarchicalIndustryList']),

    resetForm () {
      this.newTopic.categoryId = null
      this.newTopic.parentId = null
      this.resetFilteredParentTopics()
      this.newTopic.industryId = null
      this.newTopic.helpText = null
      this.newTopic.keywords = {
        inclusions: [],
        exclusions: []
      }
    },

    onChangeModule (module, isInValid) {
      this.selectedModule = module
    },
    /**
     * Handles the keyword input in inclusions field and prepares the array from the string input using the delimiter as "\n"(newline).
     * @param {String} data
     * @public
     */
    handleInclusionInput (data) {
      if (data) {
        const keywords = data.split('\n')
        if (keywords.length > 5000) {
          this.isInclusionsInvalid = true
          this.inclusionsError = 'Max 5000 keywords accepted.'
        } else {
          const objArray = []
          for (const element of keywords) {
            const temp = element.trim().split(',')
            if (temp.length !== 2) {
              this.isInclusionsInvalid = true
              this.inclusionsError = 'Invalid input'
              return
            }
            const obj = {
              attribute: temp[0], keyword: [temp[1]]
            }
            objArray.push(obj)
          }
          const attrSet = new Set(objArray.map(e => e.attribute))
          const finalArray = []
          attrSet.forEach(element => {
            const temp = []
            const o = {
              attribute: element,
              keywords: temp.concat(...objArray.filter(e => e.attribute === element).map(e => {
                return e.keyword[0].trim()
              }))
            }
            finalArray.push(o)
          })
          this.newTopic.keywords.inclusions = finalArray
          this.isInclusionsInvalid = false
          this.inclusionsError = ''
        }
      } else {
        this.newTopic.keywords.inclusions = []
        this.isInclusionsInvalid = false
        this.inclusionsError = ''
      }
    },

    /**
     * Handles the keyword input in exclusions field and prepares the array from the string input using the delimiter as "\n"(newline).
     * @param {String} data
     * @public
     */
    handleExclusionInput (data) {
      if (data) {
        const keywords = data.split('\n')
        if (keywords.length > 5000) {
          this.isExclusionsInvalid = true
          this.exclusionsError = 'Max 5000 keywords accepted.'
        } else {
          const objArray = []
          for (const element of keywords) {
            const temp = element.trim().split(',')
            if (temp.length !== 2) {
              this.isExclusionsInvalid = true
              this.exclusionsError = 'Invalid input'
              return
            }
            const obj = {
              attribute: temp[0], keyword: [temp[1]]
            }
            objArray.push(obj)
          }
          const attrSet = new Set(objArray.map(e => e.attribute))
          const finalArray = []
          attrSet.forEach(element => {
            const temp = []
            const o = {
              attribute: element,
              keywords: temp.concat(...objArray.filter(e => e.attribute === element).map(e => {
                return e.keyword[0].trim()
              }))
            }
            finalArray.push(o)
          })
          this.newTopic.keywords.exclusions = finalArray
          this.isExclusionsInvalid = false
          this.exclusionsError = ''
        }
      } else {
        this.newTopic.keywords.exclusions = []
        this.isExclusionsInvalid = false
        this.exclusionsError = ''
      }
    },

    /**
     * Gets called on input in "Topic Name" field. It sets the input value as topicName in the newTopic object used for request data.
     * @param {String} data
     */
    onChangeName (name, isInvalid) {
      this.newTopic.topicName = name
      this.isTopicNameInvalid = isInvalid
    },

    /**
     * Gets called on input in "Help Text" field. It sets the input value as helpText in the newTopic object used for request data.
     * @param {String} value help text description value
     */
    onChangehelp (value, isValid) {
      this.newTopic.helpText = value
      if (value === '') {
        this.newTopic.helpText = null
      }
    },

    /**
     * Gets called on input in "Parent Topic Name" field. It sets the input value as parentId in the newTopic object used for request data.
     * @param {Number} id Topic id to be set as parent of the new topic
     * @param {Boolean} isInvalid validity of input
     */
    onChangeParentTopicName (id, isInvalid) {
      this.newTopic.parentId = id
      this.isParentIdInvalid = isInvalid
    },

    /**
     * Gets called on category selection/change in "Category Name" field. It sets the input value as categoryId in the newTopic object used for request data.
     * and also fetch the list of topics associated to the selected category to populate the "Parent Topic Name" field
     * @param {Number} id Category id
     * @param {Boolean} isInvalid validity of input
     */
    onChangeCategoryName (id, isInvalid) {
      this.newTopic.categoryId = id
      this.isCategoryIdInvalid = isInvalid
      if (this.newTopic.categoryId != null) {
        this.getFilteredTopicList(this.newTopic.categoryId)
        this.newTopic.parentId = null
      }
    }, // get parent topic list for industry change..............................................................................................

    /**
     * Handles the form submission by validating the input and calls the addTopic API.
     * @public
     */
    handleSubmit (e) {
      this.submitted = true
      if (this.newTopic.isStandard) {
        if (!(this.isTopicNameInvalid || this.isExclusionsInvalid || this.isInclusionsInvalid)) {
          this.addTopic(this.newTopic)
        }
      } else {
        if (this.selectedModule.name === 'voc') {
          if (!(this.isTopicNameInvalid ||
            this.isParentIdInvalid ||
            this.isCategoryIdInvalid || this.isExclusionsInvalid || this.isInclusionsInvalid)) {
            this.addTopic(this.newTopic)
          }
        } else if (this.selectedModule.name === 'voe') {
          if (!(this.isTopicNameInvalid ||
            this.isParentIdInvalid ||
            this.isIndustryIdInValid || this.isExclusionsInvalid || this.isInclusionsInvalid)) {
            this.addTopic(this.newTopic)
          }
        }
      }
      // this.checkKeywordsWarnings()
    },

    // /**
    //  * Gets called from handelSubmit function to check for any duplicate keywords in Inclusions and Exclusions fields.
    //  */
    // checkKeywordsWarnings () {
    //   const intersection = this.newTopic.keywords.inclusions.filter(element => this.newTopic.keywords.exclusions.includes(element))
    //   if (intersection.length) {
    //     Snackbar({ message: 'Inclusions and Exclusions list contains some common keywords', type: 'is-warning' })
    //   }
    //   const inclusionsSet = new Set(this.newTopic.keywords.inclusions)
    //   const exclusionsSet = new Set(this.newTopic.keywords.exclusions)
    //   if (inclusionsSet.size && inclusionsSet.size !== this.newTopic.keywords.inclusions.length) {
    //     Snackbar({ message: 'Inclusions keywords list contains some duplicates', type: 'is-warning' })
    //   }
    //   if (exclusionsSet.size && exclusionsSet.size !== this.newTopic.keywords.exclusions.length) {
    //     Snackbar({ message: 'Exclusions keywords list contains some duplicates', type: 'is-warning' })
    //   }
    // },

    /**
     * Gets called on click of the cancel button. It navigates back to the Topics (list) page
     * @public
     */
    handleCancel () {
      this.$router.push('/admin/topic')
    }
  }
}
</script>

<style lang="scss" scoped>
  .new-clients-container {
    margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
    .new-client {
      .columns {
        margin: 0px;
        .required-error {
          position: absolute;
          color: red;
          position: absolute;
          font-size: 12px;
        }
        .column {
          .upload {
            width: 200px;
            height: 60px;
            align-items: center;
            justify-content: center;
            border: 1px solid #dbdbdb;
            border-radius: 4px;
            img {
              width: 200px;
              height: 58px;
              object-fit: contain;
              padding: 5px;
            }
          }
          .submit-button {
            display: flex;
            justify-content: flex-start;
            padding-top: 20px;
            .button {
              height: 100%;
              padding: 5px 10px;
              background: #2780eb;
              color: white;
              border: none;
              margin-right: 10px;
            }
          }
        }
        .column-flex {
          display: flex;
          align-items: center;
        }
      }
    }
  }

::v-deep .vue-treeselect {
          .vue-treeselect__placeholder {
              font-family: roboto;
              font-size: 14px;
          }
          .vue-treeselect__control {
            background: transparent;
            border: none;
            border: var(--tertiary-border-color) !important;
            border-radius: 4px !important;
            color: var(--user-dropdown-text-color) !important;
              input {
                color: var(--user-dropdown-text-color) !important;
              }
              .vue-treeselect__value-container {
                .vue-treeselect__single-value {
                  color: var(--user-dropdown-text-color) !important;
                  font-family: roboto;
                  font-size: 14px;
                }
              }
          }
            .vue-treeselect__menu {
              background: var(--dropdown-backgroud) !important;
              color: var(--secondary-text-color) !important;
              border: none;
              border: 1px solid lightgray;

              .vue-treeselect__option--highlight{
                background-color: var(--dropdown-background-hover) !important;
              }
            }
        }
        ::v-deep.vue-treeselect--single .vue-treeselect__option--selected {
          background: var(--dropdown-background-hover)
        }

</style>
